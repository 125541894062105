import React from 'react'
import styled from 'styled-components'

import SectionHeading from '../shared/SectionHeading'
import VerticalPadding from '../styling/VerticalPadding'

import kevin from '../../images/team/hp_team_kevin.png'
import ronnie from '../../images/team/hp_team_ronnie.png'

const teamData = [
  {
    img: kevin,
    heading: `Hi I'm Kevin Auchoybur,`,
    title: 'Managing Director',
    description:
      'After 15 years in boardroom positions serving public- and private-sector clients, upon the launch of the HMRC’s large-company RDEC scheme in 2013 Kevin began delivering R&D Tax Credits support for firms across the UK.',
  },
]

const heading = 'Get to know us'

const MeetTheTeamHomepage = () => {
  return (
    <VerticalPadding>
      <HeadingContainer>
        <p className="section-tag">MEET THE TEAM</p>
        <SectionHeading center>{heading}</SectionHeading>
      </HeadingContainer>
      <CardTeamContainer>
        {teamData.map((item, i) => (
          <CardTeam key={i}>
            <Image src={item.img} />
            <CardDescription>
              {item.heading && (
                <Heading className="p-large">{item.heading}</Heading>
              )}
              {item.title && <Title className="p-large">{item.title}</Title>}
              {item.description && (
                <Description className="p-large">
                  {item.description}
                </Description>
              )}
            </CardDescription>
          </CardTeam>
        ))}
      </CardTeamContainer>
    </VerticalPadding>
  )
}

export default MeetTheTeamHomepage

const HeadingContainer = styled.div`
  width: 100%;
  max-width: 918px;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 45px;
  }
`

const CardTeamContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 0 auto;
  max-width: 1140px;
  padding: 0 3%;

  @media (max-width: 568px) {
    width: 100%;
    padding: 0;
    flex-direction: column;
  }
`

const CardTeam = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  @media (max-width: 568px) {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
`
const Heading = styled.h6`
  font-size: 30px;
  font-weight: 700;
  @media (max-width: 568px) {
    font-size: 18px;
  }
`

const Title = styled.h6`
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 30px;
  @media (max-width: 568px) {
    font-size: 16px;
  }
`

const Description = styled.p`
  margin: 0;
  @media (max-width: 568px) {
    font-size: 14px;
  }
`

const Image = styled.img`
  width: 100%;
  height: 500px;
  min-height: 500px;
  object-fit: cover;
  object-position: 50% 0;
  margin: auto 0;
  display: block;
  transform-origin: top center;
  border-radius: 4px;
  z-index: -1;

  @media (min-width: 768px) {
    height: 100%;
    display: block;
  }
`

const CardDescription = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: -225px;
    padding: 70px 48px 65px 48px;
    width: 90%;
    min-height: 375px;
    border-radius: 4px;
    background-color: #ffffff;
    box-shadow: 0 -4px 40px 11px rgba(100, 100, 100, 0.1);
    @media (max-width: 568px) {
      min-height: auto;
      padding: 35px 40px 50px 40px;

    }
}
`
